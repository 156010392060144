<template>
  <div class="large-page">
    <breadcrumbs />

    <v-form
      :disabled="isLoading"
      class="mt-4 mt-md-8 contain-form-width mx-auto"
    >
      <v-card
        flat
        color="accent"
        :loading="isLoading ? 'primary' : null"
      >
        <v-card-text>
          <v-autocomplete
            v-model="form.selectedUsers"
            :items="userItems"
            label="Select Users"
            hide-details
            class="mb-4"
            outlined
            dense
            chips
            clearable
            deletable-chips
            multiple
            small-chips
          />

          <v-text-field
            v-model="form.name"
            label="Title"
            :hide-details="!$v.form.title.$anyError"
            :error-messages="$v.form.title.$anyError ? ['Title is required'] : null"
            @blur="$v.form.title.$touch"
            class="mb-4"
            outlined
            dense
          />

          <v-textarea
            v-model="form.text"
            :hide-details="!$v.form.text.$anyError"
            :error-messages="$v.form.text.$anyError ? ['Description is required'] : null"
            @blur="$v.form.text.$touch"
            label="Description"
            outlined
            dense
          />
        </v-card-text>

        <v-card-actions class="px-4">
          <v-spacer></v-spacer>

          <v-btn
            depressed
            color="primary"
            @click="handleSubmit"
            :loading="isLoading"
            :disabled="isLoading"
            :class="{ 'shadow--primary': !isLoading }"
          >
            Send

            <v-icon right>
              send
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
const Breadcrumbs = () => import(/* webpackChunkName: "breadcrumbs" */ "@/components/common/Breadcrumbs.vue")

const defaultFormData = () => ({
  selectedUsers: [],
  title: "",
  text: ""
})

export default {
  name: "AdminUserNotify",

  components: {
    Breadcrumbs
  },

  data: () => ({
    isLoading: false,
    form: defaultFormData(),

    userItems: [
      "John Doe",
      "Jane Doe",
      "Alan Doe",
      "Bland Doe",
      "Test Doe",
      "Another Doe",
      "Someone Doe",
      "Final Doe"
    ]
  }),

  validations: {
    form: {
      title: {},
      text: {}
    }
  },

  methods: {
    handleSubmit() {

    }
  }
}
</script>
